import React, { useState, forwardRef } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

interface CustomInputProps {
    value?: string;
    className: string;
    onClick?: () => void;
}

const CustomInput = forwardRef<HTMLButtonElement, CustomInputProps>(
  ({ value, onClick, className }, ref) => (
    <button
      type="button"
      className={className}
      onClick={onClick}
      ref={ref}
      style={{
        display: 'inline-block',
        padding: '8px 12px',
        border: '1px solid #ccc',
        borderRadius: '10px',
        fontSize: '15px',
        cursor: 'pointer',
        backgroundColor: '#fff',
        color: '#333',
        boxShadow: 'inset 0 1px 2px rgba(0, 0, 0, 0.1)',
      }}
    >
        <span
          style={{
            backgroundColor: '#08509f', // Blue badge color
            color: '#fff', // White text color
            padding: '6px 12px', // Padding for the badge
            fontSize: '14px', // Font size
            fontWeight: 'bold', // Bold text
            borderRadius: '15px', // Rounded badge
            whiteSpace: 'nowrap', // Prevent text wrapping
            boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)', // Shadow for depth
          }}
        >
          {value || 'Select a Month'}
        </span>
    </button>
  ),
);

interface MonthPickerProps {
    label?: string;
    onChange: (date: Date) => void;
}

const MonthPickerComponent: React.FC<MonthPickerProps> = ({ label, onChange }) => {
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [shouldCloseOnSelect, setShouldCloseOnSelect] = useState(false);
  const addMonths = (date : Date, months : number) : Date => {
    const result = new Date(date);
    result.setMonth(result.getMonth() + months);
    return result;
  };
  return (
    <div className="flex flex-col">
        <div className="w-full flex justify-between items-baseline">
        <span className="text-xs uppercase text-blue">
            {label}
        </span>
        </div>
        <div>
        <DatePicker
          selected={startDate}
          onChange={(date : any) => { setStartDate(date); onChange(date); setShouldCloseOnSelect(true); }}
          dateFormat="MM/yyyy"
          showMonthYearPicker
          maxDate={addMonths(new Date(), -1)}
          customInput={<CustomInput className="text-input" />}
        />
        </div>
    </div>
  );
};

export default MonthPickerComponent;
