/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import Moment from 'moment';
import Tooltip from 'react-tooltip';
import { FaPlus, FaTimes, FaEdit } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import {
  Layout, Content, Table, Button, Modal,
} from '../../components';
import { tableReducer } from '../../components/Table/TableReducer';
import { useSchoolAdminMenu } from './local-helpers/MenuItems';
import School, { IStatus } from './local-helpers/requests';
import CustomContext from '../../contexts/CustomContext';
import UpdateUser from './UpdateUser';
import { useCurrentUser } from '../../contexts/UserContext';
import ConfirmModal from '../../components/ConfirmModal';

const initialState = {
  queryPageIndex: 0,
  queryPageSize: 10,
  totalCount: 0,
};

interface IUserDepartments {
  userId: number;
  departmentIds: number[];
  departmentsToRemove: number[];
}

const Users: React.FC = () => {
  const { user } = useCurrentUser();
  const menu = useSchoolAdminMenu();
  const API = React.useMemo(() => new School(), []);
  const queryClient = useQueryClient();
  const [searchString, setSearchString] = React.useState('');
  const [isEdit, setIsEdit] = React.useState(false);
  const [userInfo, setUserInfo] = React.useState<Record<string, any>>({});
  const [userData, setUserData] = React.useState<Record<string, any>>({});
  const [show, setShow] = React.useState(false);
  const [departments, setDepartments] = React.useState<Record<string, any>[]>(
    [],
  );
  const [assignedDepartments, setAssignedDepartments] = React.useState<
    Record<string, any>[]
  >([]);
  const [initialDepartments, setInitialDepartments] = React.useState<
    Record<string, any>[]
  >([]);
  const [isSaved, setIsSaved] = React.useState(false);

  const route = useHistory();

  React.useEffect(() => () => toast.dismiss(), []);

  const [state, dispatch] = React.useReducer(tableReducer, initialState);

  const stateProvider = {
    state,
    dispatch,
  };

  const {
    data, isSuccess, isLoading, refetch,
  } = useQuery(
    ['accountants', state.queryPageIndex, state.queryPageSize, searchString],
    () => API.fetchUsers(
      state.queryPageIndex,
      state.queryPageSize,
      searchString,
      user?.schools[0]?.id,
    ),
  );

  useQuery(
    ['userDepartments', userData?.id],
    () => API.fetchUserDepartments(user?.schools[0]?.id, userData?.id),
    {
      enabled: Object.entries(userData).length > 0,
      onSuccess(ud) {
        const userDepartments = ud.data.map((d: any) => ({
          label: d?.name,
          value: d?.id,
        }));
        setAssignedDepartments(userDepartments);
        setInitialDepartments(userDepartments);
      },
    },
  );

  useQuery(
    'departments',
    () => API.fetchDepartments(100, user?.schools[0]?.id),
    {
      retry: false,
      onSuccess(dept) {
        const { data: departmentData } = dept;
        const departmentsSelection = departmentData.map((d: any) => ({
          label: d?.name,
          value: d?.id,
        }));
        setDepartments(departmentsSelection);
      },
    },
  );

  const availableDepartments = departments.filter(
    (dept) => !assignedDepartments.find((assigned) => assigned.value === dept.value),
  );

  const columns = React.useMemo(
    () => [
      {
        Header: 'First Name',
        accessor: (row: Record<string, any>) => [row?.user?.first_name],
        Cell: ({
          row: { original },
        }: Record<string, any>): React.ReactNode => original?.user?.first_name,
      },
      {
        Header: 'Last Name',
        accessor: (row: Record<string, any>) => [row?.user?.last_name],
        Cell: ({
          row: { original },
        }: Record<string, any>): React.ReactNode => original?.user?.last_name,
      },
      {
        Header: 'Email',
        accessor: (row: Record<string, any>) => [row?.user?.email],
        Cell: ({
          row: { original },
        }: Record<string, any>): React.ReactNode => original?.user?.email,
      },
      {
        Header: 'Phone number',
        accessor: (row: Record<string, any>) => [row?.user?.phone_number],
        Cell: ({
          row: { original },
        }: Record<string, any>): React.ReactNode => `+${original?.user?.phone_number}`,
      },
      {
        Header: 'Assigned Zones',
        accessor: (row: Record<string, any>) => [row?.user?.id],
        Cell: ({ row: { original } }: Record<string, any>): React.ReactNode => {
          const {
            data: thisUser,
          } = useQuery(['user', original?.user?.id],
            () => API.fetchUserDepartments(user?.schools[0]?.id,
              original?.user?.id));
          const depts = thisUser?.data?.map((d: any) => d.name);

          return (
            <div className="flex flex-wrap gap-2">
              {depts?.map((d: string) => (
                <span
                  key={d}
                  className="bg-blue-100 text-blue-800 text-xs font-medium inline-flex
                  items-center px-2.5 py-0.5 rounded  border border-blue-400"
                >
                  {d}
                </span>
              ))}
            </div>
          );
        },
      },
      {
        Header: 'Created at',
        accessor: (row: Record<string, any>) => [row?.created_at],
        Cell: ({
          row: { original },
        }: Record<string, any>): React.ReactNode => Moment(original?.created_at)
          .format('DD/MM/YYYY, h:mm:ss a')
          || 'created at',
      },
      {
        Header: 'Updated at',
        accessor: (row: Record<string, any>) => [row?.updated_at],
        Cell: ({
          row: { original },
        }: Record<string, any>): React.ReactNode => Moment(original?.updated_at)
          .format('DD/MM/YYYY, h:mm:ss a')
          || 'updated at',
      },
      {
        Header: 'Edit',
        id: 'expander',
        Cell: ({ row }: Record<string, any>) => (
          <button
            type="button"
            className="text-yellow background-transparent font-bold uppercase rounded
                outline-none focus:outline-none ease-linear transition-all
                duration-150 hover:bg-yellow-100 active:bg-green-alert text-xs px-4 py-2"
            onClick={() => {
              setUserData({
                id: row?.original?.user_id,
                name: `${row?.original?.user?.first_name} ${row?.original?.user?.last_name}`,
              });
              setShow(true);
            }}
          >
            <FaEdit size={18} />
          </button>
        ),
        SubCell: () => null,
      },
      {
        Header: 'Status',
        accessor: (row: any) => [row?.status],
        Cell: ({ row: { original } }: Record<string, any>): React.ReactNode => {
          const { mutate, isLoading: isChangingStatus } = useMutation(
            (payload: IStatus) => API.changeStatus(payload, user.schools[0].id),
            {
              onSuccess() {
                refetch();
              },
            },
          );

          const [showConfirm, setShowConfirm] = React.useState(false);

          const handleStatusChange = (): void => {
            mutate({
              action: original?.status === 'active' ? 'deactivate' : 'activate',
              user_id: original?.user_id,
            });
          };

          const handleSubmit = (e: React.FormEvent): void => {
            e.preventDefault();
            setShowConfirm(true);
          };

          const buttonStyles = original?.status === 'active'
            ? 'text-green-alertText bg-green-alertText'
            : 'text-red-alertText bg-red-alertText';

          return (
            <>
              <form onSubmit={handleSubmit}>
                <button
                  type="submit"
                  data-tip
                  data-for="status-change"
                  className={`text-xs px-2 font-medium bg-opacity-10
                     rounded py-0.5 mr-1 ${buttonStyles}`}
                  disabled={isChangingStatus}
                >
                  {isChangingStatus ? 'Changing...' : original?.status}
                </button>
              </form>

              {showConfirm && (
                <ConfirmModal
                  message={`Are you sure you want to change the status to
                     ${original?.status === 'active' ? 'inactive' : 'active'}?`}
                  onConfirm={() => {
                    handleStatusChange();
                    setShowConfirm(false);
                  }}
                  onCancel={() => setShowConfirm(false)}
                  isLoading={isChangingStatus}
                />
              )}

              <Tooltip id="status-change" type="light">
                Click to change status.
              </Tooltip>
            </>
          );
        },
      },
    ],
    [API, refetch, user.schools],
  );

  const handleSearch = (e: string): void => {
    setSearchString(e);
  };

  const handleAddDepartment = (dept: { value: any }): void => {
    setAssignedDepartments([...assignedDepartments, dept]);
    setIsSaved(false);
  };

  const handleRemoveDepartment = (dept: { value: any }): void => {
    setAssignedDepartments(
      assignedDepartments.filter((d) => d.value !== dept.value),
    );
    setIsSaved(false);
  };

  const handleClose = (): void => {
    setShow(false);
    setUserData({});
  };

  const { mutate } = useMutation(
    ({ userId, departmentIds, departmentsToRemove }: IUserDepartments) => API.updateUserDepartments(
      user?.schools[0]?.id,
      userId,
      departmentIds,
      departmentsToRemove,
    ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['user departments']);
      },
    },
  );

  const handleSave = (): void => {
    const departmentIds = assignedDepartments.map((item) => parseInt(item.value, 10));
    const currentDepartmentIds = initialDepartments.map((item) => parseInt(item.value, 10));

    const newDepartments = departmentIds.filter(
      (id: number) => !currentDepartmentIds.includes(id),
    );

    // Calculate departments to unassign
    const departmentsToRemove = currentDepartmentIds.filter(
      (id: number) => !departmentIds.includes(id),
    );

    if (departmentIds.length === currentDepartmentIds.length) {
      toast.info('No changes to departments.');
    }

    mutate({
      userId: userData.id,
      departmentIds: newDepartments,
      departmentsToRemove,
    });
    setIsSaved(true);
  };

  return (
    <>
      <UpdateUser
        isUpdateUser={isEdit}
        onClose={() => {
          setIsEdit(false);
          setUserInfo({});
        }}
        info={userInfo}
      />
      <Modal isOpen={show} onClose={handleClose} title="Zones Manager">
        <div className="p-6 max-w-md mx-auto">
          <div className="mb-6">
            <h3 className="text-lg font-medium mb-2">
              {`${userData.name}' Zones:`}
            </h3>
            <div className="flex flex-wrap gap-2">
              {assignedDepartments.length > 0 ? (
                assignedDepartments.map((dept: any) => (
                  <span
                    key={dept.value}
                    className="flex items-center bg-blue-100 text-blue-800 px-3 py-1 rounded"
                  >
                    {dept.label}
                    <button
                      type="button"
                      onClick={() => handleRemoveDepartment(dept)}
                      className="ml-2 text-blue-500 hover:text-blue-700 transition-transform
                      transform hover:scale-110"
                    >
                      <FaTimes className="text-lg" />
                    </button>
                  </span>
                ))
              ) : (
                <p className=" text-sm font-light text-red-500">
                  Sorry, this user does not have zones.
                </p>
              )}
            </div>
          </div>
          <div className="mb-6">
            <h3 className="text-lg font-medium mb-2">Available Zones:</h3>
            <div className="flex flex-wrap gap-2">
              {availableDepartments.length > 0 ? (
                availableDepartments.map((dept: any) => (
                  <span
                    key={dept.value}
                    className="flex items-center bg-green-100 text-green-800 px-3 py-1 rounded"
                  >
                    {dept.label}
                    <button
                      type="button"
                      onClick={() => handleAddDepartment(dept)}
                      className="ml-2 text-green-500 hover:text-green-700 transition-transform
                      transform hover:scale-110"
                    >
                      <FaPlus className="text-lg" />
                    </button>
                  </span>
                ))
              ) : (
                <p className=" text-sm font-light text-red-500">
                  Sorry, no available zone.
                </p>
              )}
            </div>
          </div>

          {/* Save Button */}
          <Button type="button" onClick={handleSave}>
            Save Changes
          </Button>
          {isSaved && (
            <p className="text-green-500 mt-4">Changes saved successfully!</p>
          )}
        </div>
      </Modal>
      <Layout menuItems={menu}>
        <Content title="Users">
          <CustomContext.Provider value={stateProvider}>
            <div className="w-full flex justify-end">
              <Button
                type="button"
                className="flex justify-between align-middle"
                onClick={() => route.push('/school/create-account')}
              >
                <FaPlus className="text-lg mr-2" />
                {' '}
                Add user
              </Button>
            </div>
            <Table
              searchLabel="search users"
              data={isSuccess ? data?.data : []}
              columns={columns}
              meta={isSuccess ? data?.meta : []}
              countPage={
                isSuccess
                  ? Math.ceil(state.totalCount / state.queryPageSize)
                  : undefined
              }
              loading={isLoading}
              onChangeCallback={(e: any) => handleSearch(e)}
              search
            />
          </CustomContext.Provider>
        </Content>
      </Layout>
    </>
  );
};

export default Users;
